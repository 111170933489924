import {
  IconCircleWon,
  IconCounselor,
  IconStore,
  Menu as PdsMenu,
  MenuGroup,
  MenuItem,
  MenuItemProps as PdsMenuItemProps,
  IconMegaphone,
  IconHomeDashboard,
} from '@croquiscom/pds';
import { FC, HTMLAttributes } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NoticeNewBadge } from './suffix/NoticeNewBadge';
import { User } from '@/api/rest';
import { useAgencyId, useUser } from '@/common/hooks';

interface MenuItemProps extends Pick<PdsMenuItemProps, 'label' | 'icon' | 'suffix'> {
  path: string;
  group?: string;
  children?: MenuItemProps[];
  scopes?: User['agency_account_type'][];
}

interface Props extends HTMLAttributes<HTMLUListElement> {}

export const Navigation: FC<Props> = (props) => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { agency_id } = useAgencyId();

  const handleMenuClick = (id: string) => navigate(id);

  const renderMenuItem = ({
    path,
    label,
    icon,
    suffix,
    group,
    children,
    scopes = ['MANAGER', 'MARKETER'],
  }: MenuItemProps) => {
    const to = path.replace(':agency_id', agency_id);
    if (user?.role === 'AGENCY' && !scopes.includes(user?.agency_account_type)) {
      return null;
    }
    if (children) {
      return (
        <MenuGroup key={to} id={to} label={label} icon={icon} suffix={suffix}>
          {children.map(renderMenuItem)}
        </MenuGroup>
      );
    }
    return <MenuItem key={to} id={to} groupId={group} label={label} icon={icon} suffix={suffix} />;
  };

  return (
    <PdsMenu {...props} toggleMode selectedMenu={location.pathname} onClickMenu={handleMenuClick}>
      {menus.map(renderMenuItem)}
    </PdsMenu>
  );
};

const menus: MenuItemProps[] = [
  {
    path: '/agencies/:agency_id/dashboard',
    label: '대시보드',
    icon: <IconHomeDashboard size={20} />,
    scopes: ['MANAGER'],
    children: [
      {
        path: '/agencies/:agency_id/dashboard/advertisement',
        label: '광고 성과',
      },
      {
        path: '/agencies/:agency_id/dashboard/sales',
        label: '영업 성과',
      },
    ],
  },
  {
    path: '/agencies/:agency_id/stores',
    label: '스토어 관리',
    icon: <IconStore size={20} />,
  },
  {
    path: '/agencies/:agency_id/marketers',
    label: '마케터 관리',
    icon: <IconCounselor size={20} />,
    scopes: ['MANAGER'],
  },
  {
    path: '/agencies/:agency_id/settlements',
    label: '광고비 소진 현황',
    icon: <IconCircleWon size={20} />,
  },
  // {
  //   path: '/agencies/:agency_id/setting',
  //   label: '계약 정보 수정',
  //   icon: <IconCircleInfo size={20} />,
  //   scopes: ['MANAGER'],
  // },
  {
    path: '/agencies/:agency_id/notices',
    label: '공지 사항',
    icon: <IconMegaphone size={20} />,
    suffix: <NoticeNewBadge size='small' />,
  },
];
