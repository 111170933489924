import { forwardRef, PropsWithChildren, useImperativeHandle } from 'react';
import { useForm, UseFormReturn, FieldValues, DefaultValues, FormProvider, Resolver } from 'react-hook-form';

interface BaseProps<T extends FieldValues> {
  /**
   * @deprecated defaultValues is deprecated, please use default_values instead
   */
  defaultValues?: DefaultValues<T>;
  default_values?: DefaultValues<T>;
  resolver?: Resolver<T>;
}

interface FormContextBaseProps<T extends FieldValues> extends BaseProps<T>, PropsWithChildren {}

export const createFormContext = <T extends FieldValues>(options?: BaseProps<T>) =>
  forwardRef<UseFormReturn<T>, FormContextBaseProps<T>>(
    (
      {
        default_values = options?.default_values,
        defaultValues = options?.defaultValues,
        resolver = options?.resolver,
        children,
      },
      ref,
    ) => {
      const form = useForm<T>({ defaultValues: default_values ?? defaultValues, resolver });
      useImperativeHandle(ref, () => form, [form]);
      return <FormProvider {...form}>{children}</FormProvider>;
    },
  );
