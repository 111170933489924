import { Badge, BadgeProps } from '@croquiscom/pds';
import { FC } from 'react';

interface Props extends BadgeProps {}

export const NewBadge: FC<Props> = ({ color = 'pink', kind = 'fill', size = 'medium', ...rest }) => {
  return (
    <Badge {...rest} color={color} kind={kind} size={size}>
      New
    </Badge>
  );
};
