import dayjs from 'dayjs';
import { ComponentProps, FC, memo, useMemo } from 'react';
import { useGetAdAgencyNoticeListQuery } from '@/api';
import { NewBadge } from '@/components/common';

export const NoticeNewBadge: FC<ComponentProps<typeof NewBadge>> = memo((props) => {
  const { data: { ad_agency_notice_list } = {} } = useGetAdAgencyNoticeListQuery();

  const is_new = useMemo(() => {
    const today = dayjs();
    return (
      ad_agency_notice_list?.item_list?.some(({ date_started }) =>
        today.isBefore(dayjs(date_started).add(7, 'days')),
      ) ?? false
    );
  }, [ad_agency_notice_list]);

  return is_new ? <NewBadge {...props} /> : null;
});
